//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addLablePerson } from '@/api/modular/fileStream/documentLabelManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { initWx } from '../components/minxin'

export default {
    mixins: [initWx],
    components: {
        OpenData,
    },
    data() {
        return {
            tag: {},
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            form: this.$form.createForm(this),
            persons: [],
        }
    },
    mounted() {
        this.tag = JSON.parse(decodeURIComponent(this.$route.query.tag))
        console.log(this.tag)
        this.form.setFieldsValue({ name: this.tag.name })
    },
    methods: {
        /**
         * 提交表單
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors) => {
                if (!errors) {
                    this.sub(this.persons)
                }
            })
        },
        choose_Persons(key) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(key, null, this[key])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, key, [...result, ...resPart])
                    this.form.setFieldsValue({ [key]: result.map((j) => j.userId || j.id).join() })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'deptId' : 'userId'
        },
        sub(result) {
            const data = {
                labelId: this.tag.id,
                userArray: result.map(({ userId, corpId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type: Number(type === 'departmentName'),
                })),
            }
            addLablePerson(data)
                .then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        this.$message.success('添加成功')
                        this.$router.go(-1)
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
